import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import '../components/product.css'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery'
import PaypalCheckout from '../components/Paypal'


const CLIENT = {
  sandbox: 'Ad8WbM_471-LfclF9B-8SYwwRbmwAQNYiJe28KLH6PK2DDzLxRsN2fgCvbNnTO107v00uH9m5yqJYiHp',
  production: 'AYdXzRxj9Fo-NGXVgSBuG3joCZareEXuf3OIzj3JVQ3HmBOGHiMcpwmPdGNGE-8xF0GQh_sRijp2ZJ6t'
};

const ENV = process.env.NODE_ENV === 'production'
  ? 'production'
  : 'sandbox';


class ProductPageTemplate extends React.Component {
  render() {
    const product = get(this.props, 'data.contentfulShop')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const images = [];
    product.productImages.map((gallery) => {
        images.push({
            original: `${gallery.file.url}`,
            thumbnail: `${gallery.file.url}`,
        },)
    })

    // handleSubmit (event) {
    //   const templateId = 'template_id';
    
    //   this.sendFeedback(templateId, {message_html: `Ordered `, from_name: 'Shop LZJ', reply_to: 'leszellesdejagat@gmail.com'})
    // }
  
    // sendFeedback (templateId, variables) {
    // window.emailjs.send(
    //   'gmail', templateId,
    //   variables
    //   ).then(res => {
    //     console.log('Email successfully sent!')
    //   })
    //   .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    // }

    const onSuccess = (payment) => {
      console.log('Successful payment!', payment);
      console.log('TransactionID', payment.paymentID);
    }


    const onError = (error) =>
        console.log('Erroneous payment OR failed to load script!', error);

    const onCancel = (data) =>
        console.log('Cancelled payment!', data);

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={`${product.productName} | ${siteTitle}`} />
          <div className="ppWrapper">
            <div className="ppContainer">
                <div className="ppGalery">
                    <ImageGallery items={images} showFullscreenButton={true} useBrowserFullscreen={false} showPlayButton={false} showNav={false} />
                </div>
                <div className="ppDescr">
                    <div className="ppDescrHeader">
                        <h1 className="ppTitle">{product.productName}</h1>
                        <div  className="ppPrice">
                            <h2>{product.productPrice} €</h2>
                            <PaypalCheckout
                              client={CLIENT}
                              env={ENV}
                              commit={true}
                              currency={'EUR'}
                              total={product.productPrice}
                              onSuccess={onSuccess}
                              onError={onError}
                              onCancel={onCancel}
                              descriptionText={product.productName}
                              />
                        </div>
                    </div>
                    <div 
                      className="ppDescrText" 
                      dangerouslySetInnerHTML={{
                        __html: product.productDescription.childMarkdownRemark.html,
                      }}>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div style={{
            overflow: 'scroll',
            padding: '1em'
        }}>
            <Link className="backTo" to="/boutique/"> Retourner à la boutique</Link>
        </div>
      </Layout>
    )
  }
}

export default ProductPageTemplate

export const pageQuery = graphql`
  query ProductPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulShop(slug: { eq: $slug }) {
        productName
        productDescription {
          childMarkdownRemark {
            html
          }
        }
        productPrice
        productImages {
            file {
                url
            }
        }
    }
  }
`
